import axios from "axios";
import { Config } from "../config";
import { INotification, NotificationBody } from "@/interfaces/noti";


interface ApiResponse {
  data: {
    data: string[];
    response: string[];
  };
}


export const GetNotification = async (token: string) => {
   if (!token) return;
  try {
    const data = await axios({
      method: "get",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `notification/get-notification`,
      headers: {
        "accept-language": "th",
        Authorization: "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json"
      },
    });
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};

export const PostNotiMarkasreadById = async (token: string , dataMarkasreadById:any) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `notification/set-notification-mark-as-read-by-id`,
      headers: {
        "accept-language": "th",
        Authorization: "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json"
      },
       data: dataMarkasreadById
    });
    return data.data;
  } catch (error) { 
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
};


export const GetMarkasread = async (token: string) => {
  try {
    const data = await axios({
      method: "patch",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `notification/set-notification-mark-as-read`,
      headers: {
        "accept-language": "th",
        Authorization: "Basic " + Config.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    });
    return data.data;
  } catch (error) {
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};


export const SentNotification = async (dataBoy: NotificationBody) => {
  try {
    const data = await axios({
      method: "post",
      maxBodyLength: Infinity,
      url: Config.apiEndpoint + `notification/send-notification`,
      headers: {
        "accept-language": "th",
        "Authorization": "Basic " + Config.encoded,
        "Content-Type": "application/json",
      },
      data: dataBoy,
    });
    return data;
  } catch (error) {
    console.log(error);
    // if (axios.isAxiosError(error) && error.response) {
    //   error = error.response;
    //   return error;
    // }
  }
};