import axios from 'axios';
import { ConfigMember } from '@/config';
import { useRouter } from "next/router";

export const GetProfile = async (token: string | undefined) => {
  try {
    const data = await axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/member/profile-member`,
      headers: {
        'accept-language': 'th', 
        "Authorization": "Basic " + ConfigMember.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      }
    })

 
    
    return data.data;
  } catch (error) {
   
  }
}
 


export const EditProfile = async (token: string, dataForm: any) => {
  try {
    const data = await axios({
      method: 'post',
      maxBodyLength: Infinity,
      url: ConfigMember.apiEndpoint + `v1/member/edit-profile-member`,
      headers: {
        'accept-language': 'th', 
        "Authorization": "Basic " + ConfigMember.encoded,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      data: dataForm
    })
    return data.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      error = error.response;
      return error;
    }
  }
}
