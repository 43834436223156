import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';

export const SweetAlert = (options: SweetAlertOptions) => {
  return Swal.fire(options);
};


export const showLoadingAlert = (title: string) => {
  Swal.fire({
      title,
      allowOutsideClick: false,
      didOpen: () => {
          Swal.showLoading(null);
      }
  });
};

export const hideAlert = () => {
  Swal.close();
};


export const SweetAlertDefault = async (text: string, iconType: SweetAlertIcon) => {
  const result = await SweetAlert({
    title: text || "",
    confirmButtonText: "OK",
    allowOutsideClick: false,
    icon: iconType || "info",
    reverseButtons: true,
  });
  return result;
};


export const SweetAlertNoButton = async (text: string, iconType: SweetAlertIcon) => {
  const result = await SweetAlert({
    title: text || "",
    confirmButtonText: "OK",
    allowOutsideClick: false,
    icon: iconType || "info",
    showConfirmButton: false,
    reverseButtons: true,
    timer: 1000,
    customClass: {
      title: 'fontSize-20 txt-bold'
    }
  });
  return result;
};

export const SweetAlertNoButtonSubTxt = async (
  title: string, text: string, iconType: SweetAlertIcon, timer: number
) => {
  const result = await SweetAlert({
    title: title || "",
    text: text || "",
    confirmButtonText: "OK",
    allowOutsideClick: false,
    icon: iconType || "info",
    showConfirmButton: false,
    reverseButtons: true,
    timerProgressBar: true,
    timer: timer,
    customClass: {
      title: 'fontSize-20 txt-bold'
    }
  });
  return result;
};

export const SweetAlertLoading = async (title: string) => {
  Swal.fire({
    title: title || "กำลังอัปเดตข้อมูล...",
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      Swal.showLoading(null); // Display loading spinner
    }
  });
};


export const SweetAlertConfirm = async (text: string, iconType: SweetAlertIcon) => {
  const result = await SweetAlert({
    title: text || "",
    showCancelButton: true,
    cancelButtonText: "ยกเลิก",
    confirmButtonText: "ตกลง",
    allowOutsideClick: false,
    icon: iconType || "info",
    reverseButtons: true,
  });
  return result;
};

export const SweetAlertConfirm2 = async (text: string, iconType: SweetAlertIcon, title: string) => {
  const result = await SweetAlert({
    title: title || "",
    text: text || "",
    showCancelButton: true,
    cancelButtonText: "ยกเลิก",
    confirmButtonText: "ตกลง",
    allowOutsideClick: false,
    icon: iconType || "info",
    reverseButtons: true,
  });
  return result;
};

export const SweetAlertDone = async (text: string, iconType: SweetAlertIcon) => {
  const result = await SweetAlert({
    title: text || "",
    showCancelButton: true,
    cancelButtonText: "กลับสู่หน้าหลัก",
    confirmButtonText: "รายการคำสั่งซื้อ",
    allowOutsideClick: false,
    icon: iconType || "info",
    reverseButtons: true,
  });
  return result;
};

export const SweetAlertWarningRedirectNoCancelBtn = async (text: string, url: string) => {
  const urlRedirect = url;
  const result = await SweetAlert({
    title: text,
    showCancelButton: false,
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    allowOutsideClick: false,
    icon: "error",
    reverseButtons: true,
  });
  if (result.isConfirmed) {
    window.location.href = urlRedirect;
    
  }
};
