import React from 'react'
import Image from 'next/image'
import { styled } from "@mui/material/styles";
import { Box, Typography } from '@mui/material';
import Link from "next/link";
import { Config, ImgUrl } from "@/config";

const StyledBox = styled(Box)(({ theme }) => ({
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    zIndex: 999,
    position: "fixed",
    width: "100%",
    background: "#FFF",
    top: "-1px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10 16px",
    maxWidth: "2800px",
    margin: "0 auto",
}));
export default function urlAccountSignin({
    getDataCampaign
}: any) {
  return (
   <>
          <StyledBox>


              <Typography
                  variant="h6"
                  component="div"
                  sx={{
                      flexGrow: 1, padding: "0px", mt: "0px", display: "flex",
                      pl: "15px"
                  }}
              >
                  <Link href="/">

                      <Image
                          src={
                              getDataCampaign?.logo
                                  ? `${ImgUrl.imageCampaign}/uploads/campaign/${getDataCampaign?.id}/logo/${getDataCampaign?.logo}`
                                  : `${ImgUrl.pathImg}web/logo.webp`
                          }
                          className="author-img rounded-circle"
                          alt="maaboom"
                          width={200}
                          height={30}
                          layout="fixed"
                          objectFit="contain"
                          priority
                          style={{
                              width: "150px",
                              height: "auto",
                              marginTop: "15px",
                              marginBottom: " 5px",
                              marginLeft: "25px",
                              alignItems: "center",
                          }}




                      ></Image>
                  </Link>
              </Typography>
              <Box sx={{ textAlign: "right", pr: "25px" }}>
                  <Link
                      href={Config.apiSellerEndpoint}
                      style={{ display: "inline-block" }}
                  >
                      <Typography variant="body2" sx={{ px: 1 }}>
                          เริ่มขายสินค้า/บริการ
                      </Typography>
                  </Link>

              </Box>

          </StyledBox>
   </>
  )
}