import { Config, ImgUrl } from "@/config";
import {
  Box,
  Stack,
  Drawer,
  IconButton,
  Badge,
  Button,
  Grid,
  Divider,
  Typography,
  styled,
  InputBase,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import Link from "next/link";
import MenuIcon from "@mui/icons-material/Menu";
import Image from "next/image";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useRouter } from "next/router";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: 1,
  zIndex: "999",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    border: 1,
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  width: "100%",
}));

export default function LingTopageMobile({
  handleMap,
  getDataCampaign,
  isLoading,
  itemInCart,
  showNotification,
  menuIdNoti,
  handleClickNoti,
  handleImageError,
  token,
  menuId,
  handleProfileMenuOpen,
  imageSrc,
  isMobileOrTablet,
  keyword,
  handleClickSearch,
  handleChangeInput,
}: any) {
  const router = useRouter();
  const { pathname } = router;
  const getUrl = pathname.split("/")[1] as string;
  const [isAndroid, setIsAndroid] = React.useState(false);
  const [isIos, setIsIos] = React.useState(false);
  const [isMac, setIsMac] = React.useState(false);
  const [isWindows, setIsWindows] = React.useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const handleClick = () => {
    if (isAndroid || isWindows) {
      window.location.href =
        // "/download-application";
        "https://play.google.com/store/apps/details?id=com.petsploy360innovative.petsploy";
    } else if (isIos || isMac) {
      window.location.href =
        // "https://apps.apple.com/th/app/maaboom/id1662787944?l=th";
        "https://apps.apple.com/th/app/maaboom/id1662787944?l=th";
    } else {
      window.location.href =
        // "https://play.google.com/store/apps/details?id=com.petsploy360innovative.petsploy&hl=th";
        "https://play.google.com/store/apps/details?id=com.petsploy360innovative.petsploy";
    }
  };

  const handleClearClick = () => {
    setShowGrid(false);
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  }) as any;

  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: any) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
        pb: 0,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          m: "14px",
        }}
      >
        <Link href={"/coupon"}>
          <Typography>คูปอง</Typography>
        </Link>
      </Box>
      <Divider />
      <Box
        sx={{
          m: "14px",
        }}
        onClick={handleMap}
      >
        {/* <Typography
                    sx={{
                        '&:hover': {
                            color: "#db5a00"
                        }
                    }}
                >
                    ค้นหาสถานที่บริการ
                </Typography> */}
        <Link href="/map">
          <Typography>ค้นหาสถานที่บริการ</Typography>
        </Link>
      </Box>
      <Divider />
      <Box
        sx={{
          m: "14px",
        }}
      >
        <Link href={"/community/article"}>
          <Typography>บทความ</Typography>
        </Link>
      </Box>
      <Divider />
      <Box
        sx={{
          m: "14px",
        }}
      >
        <Link href={"/community/activity"}>
          <Typography>กิจกรรม</Typography>
        </Link>
      </Box>
      <Divider />
      <Box
        sx={{
          m: "14px",
        }}
      >
        <Link href={"/community/video"}>
          <Typography>วิดีโอ</Typography>
        </Link>
      </Box>
      <Divider />
      <Box
        sx={{
          m: "14px",
        }}
      >
        <Link href={Config.apiSellerEndpoint}>
          <Typography>เริ่มขายสินค้า/บริการ</Typography>
        </Link>
      </Box>
      <Divider />
      {/* <Box
        sx={{
          m: "14px"
        }}
      >
        <Link
          href={LinkUrl.helpUrl}
        >
          <Typography>
            Help Center
          </Typography>
        </Link>

      </Box> */}
      <Divider />
      <Box
        sx={{
          m: "14px",
        }}
      >
        <Link href={"/community/verify-place"}>
          <Typography>ลงทะเบียนธุรกิจ</Typography>
        </Link>
      </Box>
      <Divider />
    </Box>
  );

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const ua = window.navigator.userAgent;
      setIsAndroid(/android/i.test(ua));
      setIsIos(/iPad|iPhone|iPod/.test(ua) && !window.MSStream);
      setIsMac(/Mac/i.test(ua));
      setIsWindows(/Win/i.test(ua));
    }
  }, []);
  return (
    <>
      {showGrid && (
        <Grid container sx={{ padding: "0px" }}>
          <Grid
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "15px",
              boxShadow: "0px 2px 4px rgba(0 , 0 , 0 , 0.2 )",
              backgroundColor: "#DB5A00",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <ClearIcon
                onClick={handleClearClick}
                style={{ cursor: "pointer", fontSize: "15px" }}
              />
              <Image
                src="https://cdn.maaboom.com/web/banner/download_app/logo-banner-download.png"
                alt="maaboom"
                width={50}
                height={50}
                style={{
                  width: "50px",
                  height: "auto",
                }}
                onClick={handleClick}
              />
              <Image
                src="https://cdn.maaboom.com/web/banner/download_app/text-banner-download.png"
                alt="maaboom"
                width={150}
                height={50}
                style={{
                  width: "auto",
                  height: "auto",
                  maxWidth: "150px",
                }}
                onClick={handleClick}
              />
            </Box>

            <Button
              variant="outlined"
              className="btn-openApp"
              sx={{ ml: "auto" }}
              onClick={handleClick}
            >
              เปิด
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        className="navbar-petsploy"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: getDataCampaign?.background_logo
            ? `url(${ImgUrl.imageCampaign}/uploads/campaign/${getDataCampaign?.id}/background_logo/${getDataCampaign?.background_logo})`
            : "",
          backgroundPosition: "center",
          p: "10px",
          mt: "10px",
        }}
      >
        <Grid container xs={7} alignItems="center">
          <Box sx={{ mr: 1, display: "inline-block" }}>
            {(["left"] as const).map((anchor: any) => (
              <Box
                key={anchor as string}
                sx={{
                  // pt: 1, pb: 0, px: 0,
                  p: 1,
                  border: "1px solid #DEE2E6",
                  display: "inline-block",
                  // alignItems: "center",
                  borderRadius: "6px",
                  maxWidth: "35px",
                }}
              >
                <Button
                  onClick={toggleDrawer(anchor as string, true)}
                  sx={{
                    p: 0,
                    width: "100%",
                    minWidth: "auto",
                  }}
                  aria-label="Open menu"
                >
                  <MenuIcon
                    sx={{
                      width: "25px",
                      height: "25px",
                      m: 0,
                      p: 0,
                      color: "#000000",
                    }}
                  />
                </Button>
                <Drawer
                  anchor={anchor as any}
                  open={state[anchor as any] as any}
                  onClose={toggleDrawer(anchor as string, false)}
                >
                  {list(anchor as string)}
                </Drawer>
              </Box>
            ))}
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <Link
              style={{ display: "inline-block", paddingTop: "5px" }}
              href="/"
            >
              <Image
                src={
                  getDataCampaign?.logo
                    ? `${ImgUrl.imageCampaign}/uploads/campaign/${getDataCampaign?.id}/logo/${getDataCampaign?.logo}`
                    : `${ImgUrl.pathImg}web/logo.webp`
                }
                className="author-img rounded-circle"
                alt="maaboom"
                width={140}
                height={30}
                layout="fixed"
                objectFit="contain"
                priority
                style={{
                  maxWidth: "140px",
                  height: "auto",
                }}
              />
            </Link>
          </Box>
        </Grid>
        <Grid
          xs={5}
          sx={{
            textAlign: "right",
            px: 1,
          }}
        >
          {isLoading === true && (
            <>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                href="/cart"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                  px: 1,
                }}
              >
                <Badge badgeContent={itemInCart} color="error">
                  <Image
                    src={`${ImgUrl.pathImg}web/cart.webp`}
                    alt="Profile"
                    width={25}
                    height={25}
                    // style={{ width: "30px", height: "30px" }}
                  />
                </Badge>
              </IconButton>
              {showNotification.length === 0 ? (
                <IconButton
                  // size="large"
                  aria-label="notifications"
                  aria-controls={menuIdNoti}
                  aria-haspopup="true"
                  onClick={handleClickNoti}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                    px: 1,
                  }}
                >
                  <Badge badgeContent={showNotification}>
                    <Image
                      src={`${ImgUrl.pathImg}web/notification.png`}
                      alt="Profile"
                      width={25}
                      height={25}
                      onError={handleImageError}
                      // style={{ width: "25px", height: "25px" }}
                    />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  size="large"
                  aria-label="notifications"
                  aria-controls={menuIdNoti}
                  aria-haspopup="true"
                  onClick={handleClickNoti}
                  sx={{
                    px: 1,
                    "&:hover": {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                >
                  <Badge badgeContent={showNotification} color="error">
                    <Image
                      src={`${ImgUrl.pathImg}web/notification.png`}
                      alt="Profile"
                      width={25}
                      height={25}
                      onError={handleImageError}
                      // style={{ width: "25px", height: "25px" }}
                    />
                  </Badge>
                </IconButton>
              )}
            </>
          )}
          {token === undefined ? (
            <Box
              aria-controls={menuId}
              onClick={handleProfileMenuOpen}
              sx={{
                display: "inline-block",
                maxWidth: "27px",
              }}
            >
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                sx={{
                  marginLeft: "-1px",
                  width: "27px",
                  height: "27px",
                  backgroundColor: "#F9A30B",
                  padding: 2,
                  "&:hover": {
                    backgroundColor: "#F9A30B", // Keep the same color as default to disable hover effect
                    boxShadow: "none", // Remove any hover shadow
                  },
                }}
              >
                <AccountCircleOutlinedIcon
                  sx={{
                    color: "#ffffff",
                  }}
                />
              </IconButton>
            </Box>
          ) : (
            <Box
              aria-controls={menuId}
              onClick={handleProfileMenuOpen}
              sx={{
                display: "inline-block",
              }}
            >
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                sx={{
                  marginLeft: "-1px",
                  padding: "7px",
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                }}
              >
                <Image
                  src={imageSrc}
                  alt="Profile"
                  width={27}
                  height={27}
                  onError={handleImageError}
                  style={{ borderRadius: "50%" }}
                />
              </IconButton>
            </Box>
          )}
        </Grid>
        {getUrl != "map" && (
          <Grid
            xs={12}
            md={8}
            sx={{
              mb: isMobileOrTablet ? 2 : 0,
              marginTop: "10px",
            }}
          >
            <Box>
              <Search
                sx={{
                  border: 1,
                  borderColor: "grey.300",
                  borderRadius: "8px",
                }}
              >
                <SearchIconWrapper>
                  <Button
                    style={{ color: "black" }}
                    onClick={handleClickSearch}
                    aria-label="Search"
                    title="Search"
                  >
                    <SearchIcon sx={{ paddingLeft: "0" }} />
                  </Button>
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={"ค้นหาคำที่ต้องการ"}
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleChangeInput}
                  value={keyword}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleClickSearch();
                    }
                  }}
                />
              </Search>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}
